"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const native_1 = require("@react-navigation/native");
const theme_1 = require("theme");
const services_1 = require("services");
const hooks_1 = require("components/hooks");
const context_1 = require("components/context");
const ModalSelectWarehouseFR = ({ onConfirmPress, onFirstWarehouseObtained, onFirstOrSelectedWarehouseObtained, onEmptyWarehouse, onAvailableWarehouses, onListRequest, ...rest }, ref) => {
    const client = (0, hooks_1.useClient)();
    const authContext = React.useContext(context_1.AuthContext);
    const newQuoteContext = React.useContext(context_1.NewQuoteContext);
    const initialFocusRef = React.useRef(null);
    const [radioValue, setRadioValue] = React.useState("");
    const [warehouseSelected, setWarehouseSelected] = React.useState(undefined); //el almacen seleccionado
    const [warehousesResponse, setWarehousesResponse] = React.useState(undefined);
    const doAlmacenesRequest = (id) => {
        const onSuccess = (extendedResponse) => {
            const response = extendedResponse.response;
            const clientType = extendedResponse.clientType;
            if (clientType && clientType != newQuoteContext?.clientType) {
                //solo aplica para cotizacion
                //si para cuando responda se ha cambiado de tipo de cliente ignoramos esta respuesta
                return;
            }
            onAvailableWarehouses?.(response?.data);
            setWarehouseSelected(currentWarehouse => {
                const whRes = currentWarehouse == null ||
                    response?.data?.find(v => v.codigo == currentWarehouse.codigo) == null
                    ? response?.data?.[0]
                    : currentWarehouse;
                onFirstWarehouseObtained?.(undefined);
                onFirstWarehouseObtained?.(response?.data?.[0]);
                onFirstOrSelectedWarehouseObtained?.(undefined);
                onFirstOrSelectedWarehouseObtained?.(whRes);
                return whRes;
            });
            setRadioValue(whCode => {
                const whRes = (whCode ?? "").length == 0 ||
                    response?.data?.find(v => v.codigo == whCode) == null
                    ? response?.data?.[0]?.codigo ?? ""
                    : whCode;
                return whRes;
            });
            if ((response?.data?.length ?? 0) == 0)
                onEmptyWarehouse?.();
            setWarehousesResponse(response);
        };
        const onFail = (error) => {
            if (true) {
                onFirstWarehouseObtained?.(undefined);
                setWarehouseSelected(undefined);
                setRadioValue("");
            }
            setWarehousesResponse({ msg: error });
            onEmptyWarehouse?.();
        };
        const onFailSessionExpired = authContext?.signOut;
        (0, services_1.doAlmacenes)({
            params: {
                clientType: newQuoteContext?.clientType,
                data: {
                    uid: id,
                },
            },
            callback: {
                onSuccess,
                onFail,
                onFailSessionExpired,
            },
        });
    };
    let doAlmacenesRequestWithoutArgs = () => {
        if (newQuoteContext) {
            if (newQuoteContext?.cliid)
                doAlmacenesRequest(newQuoteContext?.cliid);
            else {
                setWarehousesResponse({ msg: "No hay cliente seleccionado" });
            }
        }
        else {
            doAlmacenesRequest(client?.id);
        }
    };
    onListRequest?.(doAlmacenesRequestWithoutArgs);
    (0, native_1.useFocusEffect)(React.useCallback(() => {
        doAlmacenesRequestWithoutArgs();
        return () => {
            // Do something when the screen is unfocused
            // Useful for cleanup functions
        };
    }, [
        newQuoteContext?.cliid,
        newQuoteContext?.existentWarehouse?.codigo,
        client?.id,
    ]));
    React.useImperativeHandle(ref, () => ({
        onOpen: () => {
            doAlmacenesRequestWithoutArgs();
        },
    }), [
        newQuoteContext?.cliid,
        newQuoteContext?.existentWarehouse?.codigo,
        client?.id,
    ]);
    const radios = warehousesResponse?.data?.map(warehouse => (<native_base_1.Radio value={warehouse.codigo ?? ""} w="100%" my="9" key={warehouse.codigo}>
			{warehouse.nombre || ""}
		</native_base_1.Radio>));
    const header = (0, native_base_1.useBreakpointValue)({
        base: theme_1.Str.selectAPurchaseStock,
        md: theme_1.Str.selectA_purchaseStock,
    });
    return (<native_base_1.Modal {...rest} initialFocusRef={initialFocusRef}>
			<native_base_1.Modal.Content>
				<native_base_1.Modal.CloseButton />
				{warehousesResponse?.data && (<native_base_1.Modal.Header>{header}</native_base_1.Modal.Header>)}

				<native_base_1.Modal.Body>
					{warehousesResponse?.msg && (<native_base_1.Text variant="body23" color="colorVividRed1" my="25" textAlign="center">
							{warehousesResponse?.msg ?? ""}
						</native_base_1.Text>)}
					<native_base_1.View>
						<native_base_1.Radio.Group name="" style={styles.radioGroup} value={radioValue} onChange={nextValue => {
            setRadioValue(nextValue);
        }}>
							{radios}
						</native_base_1.Radio.Group>
					</native_base_1.View>
				</native_base_1.Modal.Body>
				{warehousesResponse?.data && (<native_base_1.Modal.Footer>
						<native_base_1.Button w={{ base: "100%", md: "auto" }} textTransform="uppercase" onPress={() => {
                if (radioValue == null)
                    return;
                onConfirmPress(warehousesResponse?.data?.find(element => element.codigo == radioValue));
                setWarehouseSelected(warehousesResponse?.data?.find(element => element.codigo == radioValue));
            }}>
							{theme_1.Str.confirm}
						</native_base_1.Button>
					</native_base_1.Modal.Footer>)}
			</native_base_1.Modal.Content>
		</native_base_1.Modal>);
};
const styles = react_native_1.StyleSheet.create({
    modal: {
        backgroundColor: theme_1.Colors.colorModalBg,
        borderColor: theme_1.Colors.colorModalBorder,
        borderRadius: theme_1.Sizes.modalRadius,
        borderWidth: theme_1.Sizes.modalBorderWidth,
    },
    radioGroup: {},
    radio: {
        marginTop: 10,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderColor: theme_1.Colors.colorVeryLightGray3,
        borderWidth: 1,
        borderRadius: theme_1.Sizes.configRadius,
    },
});
const ModalSelectWarehouse = React.forwardRef(ModalSelectWarehouseFR);
exports.default = ModalSelectWarehouse;
