import React from "react";
import {View, Text} from "native-base";

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}

	static getDerivedStateFromError(error) {
		// Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
		return {hasError: true};
	}

	componentDidCatch(error, errorInfo) {
		// También puedes registrar el error en un servicio de reporte de errores
		//logErrorToMyService(error, errorInfo);
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// Puedes renderizar cualquier interfaz de repuesto
			return (
				<View>
					<Text>Something went wrong.</Text>
				</View>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
