"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageKey = exports.UserType = void 0;
exports.UserType = {
    SN: "SN",
    RV: "RV",
};
exports.StorageKey = {
    LOGIN_DATA: "@loginData",
    CLIENT_DATA: "@clientData",
    CLIENT_QUOTE_DATA: "@clientQuoteData",
    SAVE_PASSWORD: "@savePassword",
    BANNER: "@Banner",
    DEUDA: "@Deuda",
    FTOKEN: "@ftoken",
    FPUSH: "@push",
};
